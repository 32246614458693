import React from 'react';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';

const PageBanner = ({ bannerImage, pageTitle }) => (
	<div className="w-100 overflow-hidden page-banner items-center relative overflow-hidden page-banner">
		<Img fluid={bannerImage.localFile.childImageSharp.fluid} className="w-100 h-auto z-0" />
		<div className="page-heading pl4 pr4 pl5-l pr5-l z10 w-100">
			<Fade>
				<h1 className="f2 f-subheadline-l black measure g-medium lh-copy pl4-l mb0 mt4 mt0-l">
					{pageTitle}
				</h1>
			</Fade>
		</div>
	</div>
);

export default PageBanner;
