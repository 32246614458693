import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import MapStyles from '../css/MapStyles';

export class MapContainer extends Component {
  render() {
    const { location } = this.props;
    return (
      <Map
        google={this.props.google}
        initialCenter={{
          lat: location.latitude,
          lng: location.longitude,
        }}
        zoom={18}
        styles={MapStyles}
        disableDefaultUI='true'
        zoomControl='true'
      >
        <Marker
          position={{ lat: location.latitude, lng: location.longitude }}
        />
        <Marker />
      </Map>
    );
  }
}
// Need to fix icon double up bug

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD6CJc1o3yowJoC1WRF8_VBjF2ynfOKvcw',
})(MapContainer);
