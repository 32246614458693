import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
// import FaceBookIcon from './FaceBookIcon';
// import TwitterIcon from './TwitterIcon';
import BraveStrap from './BraveStrap';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        prismicGlobals {
          data {
            company_description {
              text
            }
            contact_details {
              html
            }
            physical_address {
              html
            }
            facebook_link {
              url
            }
            twitter_link {
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer className="pl4 pr4 pl5-l pr5-l bg-black pt4 pb4 pt5-l pb5-l white">
        <div className="flex-l justify-between">
          <div className="f4 f3-l measure white lh-copy w-100 w-30-l mb4">
            {data.prismicGlobals.data.company_description.text}
          </div>
          <div className="w-100 w-60-l flex-l justify-between lh-copy items-top">
            <div
              className="f5 measure"
              dangerouslySetInnerHTML={{ __html: data.prismicGlobals.data.physical_address.html }}
            />
            <div className="f5 measure">
              <div
                dangerouslySetInnerHTML={{ __html: data.prismicGlobals.data.contact_details.html }}
              />
            </div>
            <div className="pl3-l tr-l">
              <div className="footer-logo mt4 mb4 mt0-l mb0-l" />
              <ul className="list">
                <li className="dib">
                  <a
                    href="http://100.newzealand.co.nz/validate.php?userid=9803"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="nz-approved"
                  >
                    Certified Organic
                  </a>
                </li>
              </ul>
              <a
                href="http://www.biogro.co.nz/"
                target="_blank"
                rel="noopener noreferrer"
                class="organic dib"
              ></a>
            </div>
          </div>
        </div>
        <div className="bt flex-l tc tl-l b--white-50 grey justify-between f6 items-center pt3 footer-grey mt4-l lh-copy">
          <div>
            <Link to="/compliance" className="no-underline">
              Compliance &amp; Certifications
            </Link>
          </div>
          <div className="mt2 mb2">
            &copy;{new Date().getFullYear()} Pure Bottling, All Rights Reserved
          </div>
          <BraveStrap />
        </div>
      </footer>
    )}
  />
);

export default Footer;
