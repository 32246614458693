import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { useSpring, animated } from 'react-spring';

const ServiceMenu = ({ menuState }) => {
  const { x } = useSpring({
    x: menuState ? 0 : 1
  });
  return (
    <StaticQuery
      query={graphql`
        query {
          allPrismicService(
            filter: { tags: { nin: "Content-Model" } }
            sort: { fields: [data___page_weight], order: ASC }
          ) {
            edges {
              node {
                uid
                data {
                  service_name {
                    text
                  }
                  page_weight
                }
              }
            }
          }
        }
      `}
      render={data => (
        <animated.div
          className="service-menu mr3 relative"
          style={{
            opacity: x.interpolate(x => x)
          }}
        >
          <ul className="list flex justify-between">
            {data.allPrismicService.edges.map(serviceItem => (
              <li>
                <Link
                  to={`${serviceItem.node.uid}/`}
                  className="link white semi-bold f6 ml4 mr4 relative db"
                  activeClassName="active"
                >
                  <span className="db absolute" />
                  {serviceItem.node.data.service_name.text}
                </Link>
              </li>
            ))}
          </ul>
        </animated.div>
      )}
    />
  );
};

export default ServiceMenu;
